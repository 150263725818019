import type { AxiosPromise } from "axios";

import { httpClient } from "@/shared/api";

import { type ISettingRow } from "..";

export class GlobalSettingsApi {
    static async fetchAll(): Promise<ISettingRow[]> {
        const response = await httpClient.get("/admin/global-settings");
        return response.data;
    }
}
