<template>
    <Seo />
    <RouterView></RouterView>
    <Toast position="bottom-center"></Toast>
    <ConfirmDialog class="w-3" :draggable="false" />
    <DynamicDialog />
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { useRecaptchaProvider } from "vue-recaptcha";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import DynamicDialog from "primevue/dynamicdialog";
import { Seo } from "@/shared/ui/marketing";
import { useGlobalSettingStore } from "@/entities/GlobalSettings";
import { useSessionStore } from "@/entities/Session";
import { API_URL } from "@/shared/config";
import formbricks from "@formbricks/js/app";
import { storeToRefs } from "pinia";
import * as Sentry from "@sentry/vue";

const storeGlobalSettings = useGlobalSettingStore();
const sessionStore = useSessionStore();
const { isAuth, userData } = storeToRefs(sessionStore);

useRecaptchaProvider();

declare global {
    interface Window {
        chatwootSDK: any;
        $chatwoot: any;
    }
}

async function importFormbricks() {
    if (typeof window !== "undefined") {
        await formbricks.init({
            environmentId: "cm1c1kq73000b10d7g2219udx",
            apiHost: "https://survey.stormbpmn.com",
            userId: String(userData.value.userId),
            attributes: {
                email: userData.value.username,
                avatar_url: API_URL + "files/username/" + userData.value.username,
                plan: userData.value.plan,
                teamId: userData.value.teamId || "",
                teamName: userData.value.teamName || "",
                validTo: String(userData.value.validTo),
            },
        });
    }
}
function importChatwoot() {
    const chatwoot = document.createElement("script");
    const BASE_URL = "https://helpdesk.stormbpmn.com";
    chatwoot.setAttribute("src", BASE_URL + "/packs/js/sdk.js");
    chatwoot.defer = true;
    chatwoot.async = true;
    document.head.appendChild(chatwoot);

    chatwoot.onload = function () {
        window.chatwootSDK.run({
            websiteToken: "DSh7wKvZDRCJZk3GfBUXKFB2",
            baseUrl: BASE_URL,
        });
        setChatwootSeettings();
    };
}
function setChatwootSeettings() {
    if (userData.value) {
        window.$chatwoot.setUser(userData.value.username, {
            email: userData.value.username,
            avatar_url: API_URL + "files/username/" + userData.value.username,
        });
        window.$chatwoot.setCustomAttributes({
            plan: userData.value.plan,
            teamId: userData.value.teamId,
            teamName: userData.value.teamName,
            validTo: userData.value.validTo,
        });
    }
}

watch(isAuth, () => {
    if (isAuth.value) {
        setChatwootSeettings();
        importFormbricks();
    } else {
        window.$chatwoot.reset();
        formbricks.logout();
    }
});

onMounted(async () => {
    storeGlobalSettings.fetchAll();
    importChatwoot();
    if (isAuth.value) {
        await importFormbricks();
        sessionStore.setSentrySettings();
    }
});
</script>
